import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import { MessageSquareDiff } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import * as React from "react";

import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icon";
import { API_URL } from "@/config";
import axios from "axios";


import { UserRoundPlus } from "lucide-react";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { useNavigate } from "react-router-dom";
import { getRandomQuote } from "./quotes";

export function AlertDestructiveMessage({ msg }) {
  if (!msg) return <></>;
  return <Alert variant="destructive">{msg}</Alert>;
}

export function Login({ className, ...props }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [subscription, setSubscription] = React.useState("");
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const quote = getRandomQuote()

  React.useEffect(() => {
    // Check if token is in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // If token exists, navigate to a protected route (e.g., dashboard)
      navigate("/"); // Change this to your desired protected route
    }
  }, [navigate]);

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      const response = await axios.post(`${API_URL}/api/v1/token/`, {
        email,
        password,
      });
      if (response.data.access) {
        console.log(response.data.access);

        // Include subscription data in the body
        const subscriptionData = {
          // Add required data for subscription creation (e.g., plan ID, billing details)
        };

        const subscription = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          subscriptionData, // Include subscription data
          {
            headers: {
              Authorization: `Bearer ${response.data.access}`,
            },
          }
        );
      }
      localStorage.setItem("token", response.data.access);
      localStorage.setItem("refresh", response.data.refresh);
      const path = localStorage.getItem("ldirect");
      if (path && subscription && subscription.data.has_access == true) {
        localStorage.removeItem("ldirect");
        navigate(path);
      } else {
        navigate("/pricing/");
      }
    } catch (error) {
      console.log(error);
      setError("Enter valid email or password!");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <AlertDestructiveMessage msg={error} />
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="password">
              Password
            </Label>
            <Input
              id="password"
              placeholder="*********"
              type="password"
              autoCorrect="off"
              disabled={isLoading}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button className="bg-info" disabled={isLoading}>
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Login
          </Button>
          {/* <Button
            className="bg-info"
            disabled={isLoading}
            onClick={() => navigate("/account/")}
          >
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Sign Up
          </Button> */}
        </div>
      </form>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">Or</span>
        </div>
      </div>

      <div className="flex flex-col space-y-2 text-center">
        <p className="text-sm text-muted-foreground ">
          <b>Need to create an account</b>
        </p>
        <Button className="bg-info" onClick={() => navigate('/account/')} variant="outline" type="button" disabled={isLoading}>
          {isLoading ? (
            <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <UserRoundPlus className="mr-2 h-4 w-4" />
          )}{" "}
          Sign Up
        </Button>
      </div>
    </div>
  );
}

export const metadata = {
  title: "Authentication",
  description: "Authentication forms built using the components.",
};

export function SignIn() {
  const navigate = useNavigate();
  const quote = getRandomQuote()
  return (
    <>
      <div className="md:hidden">
        <img
          src="https://dev.scrollne.ws/logo_nemesis.png"
          width={1280}
          height={843}
          alt="Authentication"
          className="block dark:hidden p-4"
          onClick={() => navigate("/")}
        />
        <img
          src="https://dev.scrollne.ws/logo_nemesis.png"
          width={1280}
          height={843}
          alt="Authentication"
          className="hidden dark:block"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="container relative h-[800px] flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-info" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <a style={{ hover: "none" }} href="/">
              scrollne.ws
            </a>
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;{quote.quote}&rdquo;
              </p>
              <footer className="text-sm">{quote.artist}</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Login to your account
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email & password below for login!
              </p>
            </div>
            <Login />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export function LoginPage() {
  return (
    <Card className="mx-auto max-w-sm mg-top-space">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Login</CardTitle>
        <CardDescription>
          Enter your email and password to login to your account
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              placeholder="m@example.com"
              required
              type="email"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">Password</Label>
            <Input
              placeholder="********"
              id="password"
              required
              type="password"
            />
          </div>
          <Button className="w-full bg-info" type="submit">
            Login
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
