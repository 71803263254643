// List of quotes
const quotes = [
    {
      artist: "J.K. Rowling",
      quote: "It takes a great deal of bravery to stand up to our enemies, but just as much to stand up to our friends."
    },
    {
      artist: "Albert Einstein",
      quote: "If you can't explain it to a six year old, you don't understand it yourself."
    },
    {
      artist: "Bob Marley",
      quote: "You may not be her first, her last, or her only. She loved before she may love again. But if she loves you now, what else matters? She's not perfect—you aren't either, and the two of you may never be perfect together but if she can make you laugh, cause you to think twice, and admit to being human and making mistakes, hold onto her and give her the most you can. She may not be thinking about you every second of the day, but she will give you a part of her that she knows you can break—her heart. So don't hurt her, don't change her, don't analyze and don't expect more than she can give. Smile when she makes you happy, let her know when she makes you mad, and miss her when she's not there."
    },
    {
      artist: "Dr. Seuss",
      quote: "I like nonsense, it wakes up the brain cells. Fantasy is a necessary ingredient in living."
    },
    {
      artist: "Douglas Adams",
      quote: "I may not have gone where I intended to go, but I think I have ended up where I needed to be."
    },
    {
      artist: "Elie Wiesel",
      quote: "The opposite of love is not hate, it's indifference. The opposite of art is not ugliness, it's indifference. The opposite of faith is not heresy, it's indifference. And the opposite of life is not death, it's indifference."
    },
    {
      artist: "Friedrich Nietzsche",
      quote: "It is not a lack of love, but a lack of friendship that makes unhappy marriages."
    },
    {
      artist: "Mark Twain",
      quote: "Good friends, good books, and a sleepy conscience: this is the ideal life."
    },
    {
      artist: "Allen Saunders",
      quote: "Life is what happens to us while we are making other plans."
    },
    {
      artist: "Pablo Neruda",
      quote: "I love you without knowing how, or when, or from where. I love you simply, without problems or pride: I love you in this way because I do not know any other way of loving but this, in which there is no I or you, so intimate that your hand upon my chest is my hand, so intimate that when I fall asleep your eyes close."
    },
    {
      artist: "Ralph Waldo Emerson",
      quote: "For every minute you are angry you lose sixty seconds of happiness."
    }
  ];
  
  // Function to get a random quote
  export function getRandomQuote() {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  }
  
//   // Example usage
//   const randomQuote = getRandomQuote();
//   console.log(`"${randomQuote.quote}" - ${randomQuote.artist}`);
  