import { SiteHeader } from "@/routes/site-header";
import { Footer } from "@/routes/footer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "@/config";
import { useNavigate, useLocation } from "react-router-dom";
import { Icons } from "@/components/ui/icon";
import {
  AlertCircle,
  BadgePlus,
  BadgeMinus,
  Filter,
  Banknote,
  StepForward,
} from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DataTableFeed } from "@/routes/feeds-confirm-table";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Navbar } from "./navbar";

export function AlertDestructive() {
  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>You can not select paid feed.</AlertDescription>
    </Alert>
  );
}

export function AlertSuccess() {
  return (
    <Alert variant="success">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>!!!</AlertTitle>
      <AlertDescription>Successfully change saved!</AlertDescription>
    </Alert>
  );
}


export function SuccessSelect({ open, setShowModal }) {
  return (
    <Dialog open={open} onOpenChange={setShowModal}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <AlertSuccess />
        </DialogHeader>

        <DialogFooter>
          <Button
            className="bg-success" 
            onClick={() => setShowModal(false)}
            type="submit"
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function CannotSelect({ open, setShowModal }) {
  return (
    <Dialog open={open} onOpenChange={setShowModal}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <AlertDestructive />
        </DialogHeader>

        <DialogFooter>
          <Button
            className="bg-info"
            onClick={() => setShowModal(false)}
            type="submit"
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function ConfirmFeeds({
  open,
  setShowModal,
  choices,
  addChoices,
  loading = false,
}) {
  if (choices.length == 0) {
    return <></>;
  }
  let feed = choices[0];

  return (
    <Dialog open={open} onOpenChange={setShowModal}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Confirm feeds</DialogTitle>
          <DialogDescription>Click save when you're done.</DialogDescription>
        </DialogHeader>
        <DataTableFeed feed_data={choices} />
        <DialogFooter>
          <Button
            className="bg-info"
            onClick={() => addChoices()}
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              `Save changes`
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function SelectFeeds() {
  return (
    <>
      <Feeds></Feeds>
    </>
  );
}

export function SearchFeeds({ onSearch, onFilterChange }) {
  const [feeds, setFeeds] = useState([]);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [debouncedValue, setDebouncedValue] = useState("");

  const [showAll, setAll] = useState(true);
  const [showFree, setshowFree] = useState(false);
  const [showPremoum, setShowPremoum] = useState(false);
  let showAllTrigger = true;

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchValue.length >= 1) {
        setDebouncedValue(searchValue);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    if (showFree && showPremoum) {
      setAll(true);
      onFilterChange(true, false, false);
    } else if (showFree) {
      setAll(false);
      onFilterChange(false, true, false);
    } else if (showPremoum) {
      setAll(false);
      onFilterChange(false, false, true);
    } else if (showAll) {
      setshowFree(true);
      setShowPremoum(true);
    } else if (!showFree && !showPremoum && !showAll) {
      setAll(true);
    }
  }, [showAll, showFree, showPremoum]);

  useEffect(() => {
    setSearchKey(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    const fetchData = async () => {
      if (!searchValue) {
        const response = await axios.get(`${API_URL}/api/v1/news/providers/`);
        setFeeds(response.data);
        onSearch(response.data);
        return;
      }
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/news/providers/?search=${searchValue}`
        );
        setFeeds(response.data);
        onSearch(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchValue]); // Include searchValue in the dependency array
  return (
    <form className="max-w-md mx-auto py-3">
      <div className="relative flex">
        <label className="relative flex-grow">
          <span className="sr-only">Search</span>
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <Icons.searchIcon></Icons.searchIcon>
          </span>
          <input
            className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="Search for feeds..."
            type="text"
            name="search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="ml-2 p-2 bg-white border  border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1  h-10 flex justify-center"
              variant="outline"
            >
              <Filter />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>Feed Types</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuCheckboxItem
              checked={showAll}
              onCheckedChange={setAll}
            >
              All
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={showFree}
              onCheckedChange={setshowFree}
            >
              Free
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={showPremoum}
              onCheckedChange={setShowPremoum}
            >
              Basic/Premimums
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </form>
  );
}
export function Feed({ feed, onAdd, choices }) {
  const isThere = choices.some((choice) => choice.alias === feed.alias);

  return (
    <div className="flex flex-col p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow w-full max-w-[350px] border border-gray-200 dark:border-gray-700">
      {/* Icon / Logo */}
      <div className="flex items-center mb-3">
        <img
          className="w-12 h-12 rounded-full object-cover border border-gray-300 dark:border-gray-600 shadow-sm" // Added border and shadow
          src={feed.icon ? feed.icon : `https://dev.scrollne.ws/logo_nemesis.png`}
          alt={feed.display_name || feed.name}
        />
        <div className="flex flex-col ml-3 flex-grow">
          <span className="text-base font-bold text-gray-900 dark:text-white">
            {feed.display_name || feed.name}
          </span>
          <span className="text-xs text-gray-500 dark:text-gray-400">
            {feed.total_news} news
          </span>
        </div>
      </div>

      {/* Short Description */}
      <p className="text-sm text-gray-900 dark:text-white flex-grow mb-3">
        {feed.short_description || "No description available."}
      </p>

      {/* Button Section */}
      <button
        onClick={() => onAdd(feed)}
        className={`mt-auto w-full flex items-center justify-center gap-2 py-2 text-sm font-medium rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          isThere
            ? "bg-red-500 text-white hover:bg-red-600"
            : "bg-green-500 text-white hover:bg-green-600"
        }`}
      >
        {isThere ? (
          <>
            <BadgeMinus className="h-4 w-4" />
            Remove ({feed.installed})
          </>
        ) : (
          <>
            {feed.non_profit_org ? (
              <BadgePlus className="h-4 w-4" />
            ) : (
              <Banknote className="h-4 w-4" />
            )}
            Add ({feed.installed})
          </>
        )}
      </button>
    </div>
  );
}


export function Feeds() {
  const [searchFeeds, setSearchFeeds] = useState();
  const [allFeeds, setAllFeeds] = useState([]);
  const [subscription, setSubscription] = useState();
  const [added, setAdded] = useState(null);
  const [choices, setChoices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, showError] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/news/providers/`);
        setSearchFeeds(response.data);
        setAllFeeds(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [subscription]);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${API_URL}/api/v1/payments/subscription/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.data.alias) {
          navigate("/pricing/");
        }
        if (response.data.feeds.length >= 1) {
          navigate("/");
        }
        setSubscription(response.data);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    fetchSubscription();
  }, []);

  const handleAdd = (feed) => {
    setChoices((prevChoices) => {
      if (
        subscription &&
        subscription.plan.max_allowed_feed == 0 &&
        feed.non_profit_org == false
      ) {
        console.log("error");
        showError(true);
        return prevChoices;
      }
      const isThere = prevChoices.some((choice) => choice.alias === feed.alias);
      if (isThere) {
        return prevChoices.filter((choice) => choice.alias !== feed.alias);
      } else {
        if (
          subscription &&
          subscription.plan.max_allowed_feed == 0 &&
          feed.non_profit_org == true
        ) {
          return [...prevChoices, feed];
        }
        if (feed.non_profit_org == true) {
          return [...prevChoices, feed];
        }
        let counter = prevChoices.filter(
          (choice) => choice.non_profit_org == false
        );

        if (
          counter.length >= subscription.max_selection &&
          subscription &&
          subscription.plan.max_allowed_feed > 0
        ) {
          setShowModal(true);
          return prevChoices;
        }
        return [...prevChoices, feed];
      }
    });
  };
  useEffect(() => {
    let counter = choices.filter((ch) => ch.non_profit_org === false);
    if (
      subscription &&
      counter.length >= subscription.max_selection &&
      subscription.plan.max_allowed_feed > 0
    ) {
      setShowModal(true);
    }
  }, [choices, subscription]);

  const handleAddChoices = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/api/v1/payments/subscription/feeds/`,
        { choice: choices },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/");
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
  };

  const showFilteredData = (all, free, premium) => {
    // Ensure allFeeds is defined and accessible within this function's scope
    if (!allFeeds) {
      console.error(
        "allFeeds is not defined. Please ensure it's available before calling showFilteredData."
      );
      return;
    }

    const filteredFeeds = allFeeds.filter((feed) => {
      if (all) {
        return true;
      }

      if (free && feed.non_profit_org) {
        return true;
      }

      if (premium && !feed.non_profit_org) {
        return true;
      }

      return false;
    });

    setSearchFeeds(filteredFeeds);
    return filteredFeeds;
  };

  return (
    <>
      {token ? (
        <Navbar firehouse={false} setFilterConfig={null} />
      ) : (
        <SiteHeader />
      )}
      <CannotSelect open={error} setShowModal={showError} />
      <ConfirmFeeds
        choices={choices}
        open={showModal}
        setShowModal={setShowModal}
        addChoices={handleAddChoices}
        loading={loading}
      />

      <div className="xl:container mx-auto px-3 sm:px-6 xl:px-2">
        <SearchFeeds
          onSearch={setSearchFeeds}
          onFilterChange={showFilteredData}
        ></SearchFeeds>
        <h2 className="text-center">
          You can select all free feeds and your subscribed based on the payment
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 place-items-start py-6">
          {searchFeeds &&
            searchFeeds.map((item, index) => (
              <Feed
                choices={choices}
                onAdd={handleAdd}
                key={index}
                feed={item}
              />
            ))}
        </div>

        {choices && choices.length > 0 && (
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            <StepForward />
          </button>
        )}
      </div>

      <Footer />
    </>
  );
}
