import { Separator } from "@/components/ui/separator";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_URL } from "@/config";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";

import { Upload, CheckCircle } from "lucide-react";
import { Badge } from "@/components/ui/badge";

import { CannotSelect, ConfirmFeeds, SuccessSelect } from "@/routes/feeds";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

const handleCloseDoneAlert = () => {
  setDone(false);
};

const CenteredLoader = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
    </div>
  );
};

export function DoneAlert({ open, setClose }) {
  useEffect(() => {
    if (!open) {
      console.log("DoneAlert closed");
    }
  }, [open]);

  return (
    <AlertDialog open={open} onOpenChange={setClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Done</AlertDialogTitle>
          <AlertDialogDescription>
            Successfully updated your feeds.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              setClose(false);
            }}
          >
            Close
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function CheckboxWithText({ feed, checked, onChange, disabled }) {
  return (
    <div className="items-top flex space-x-2">
      <Checkbox
        disabled={disabled}
        checked={checked}
        id={feed.alias}
        onCheckedChange={onChange}
        style={{ border: "2px solid #000" }}
        className={`h-5 w-5 border-2 border-gray-300 rounded-md transition-all
                    ${checked ? "bg-blue-500 border-blue-500" : "bg-white"}
                    hover:border-blue-400 focus:ring focus:ring-blue-300 focus:outline-none`}
      />
      <div className="grid gap-0.5 leading-none">
        <label
          htmlFor={feed.alias}
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          <a href={`/provider/${feed.slug}/`}>
            {feed.display_name || `Unknown`}
          </a>
        </label>
        <p className="text-sm text-muted-foreground">
          {feed.short_description || `Unknown`}
        </p>
      </div>
    </div>
  );
}

export function FeedList() {
  const [loading, setLoading] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [error, showError] = useState(false);
  const [providers, setProviders] = useState([]);
  const [selectedFeeds, setSelectedFeeds] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [disabledFeeds, setDisabledFeeds] = useState({});
  const [display_feeds, setDisplayFeeds] = useState({});
  const [confrimSelection, setConfirmSelection] = useState(false);
  const [paidFeeds, setPaidFeedsCount] = useState(0);
  const [maxSelection, setMaxSelection] = useState(0);
  const [done, setDone] = useState(false);
  const navigate = useNavigate();
  const initialized = useRef(false);

  const fetchSubscription = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/api/v1/payments/subscription/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubscription(response.data);
      setSelectedFeeds(response.data.display_feeds)
      setMaxSelection(response.data.max_selection - paidFeeds);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching subscription:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscribedFeeds = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let header = {};
    if (token) {
      header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    try {
      const subs = await axios.get(
        `${API_URL}/api/v1/users/feeds/subscribed/`,
        header
      );
      setFeeds(subs.data);

      const initialSelection = {};
      subs.data.forEach((feed) => {
        initialSelection[feed.alias] = true;
      });
      setDisabledFeeds(initialSelection);
      const nonProfitFeedsCount = subs.data.filter(
        (feed) => !feed.non_profit_org
      ).length;
      setPaidFeedsCount(nonProfitFeedsCount);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        navigate("/account/");
      }
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProviders = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let header = {};
    if (token) {
      header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    try {
      const feeds = await axios.get(
        `${API_URL}/api/v1/news/subscribed/providers/`,
        header
      );
      setProviders(feeds.data);
      // const selected = feeds.data.filter((item) => disabledFeeds[item.alias]);
      // setSelectedFeeds(selected);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        navigate("/account/");
      }
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      fetchSubscribedFeeds();
      fetchSubscription();
      initialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (Object.keys(disabledFeeds).length > 0) {
      fetchProviders(); // Fetch providers after setting disabledFeeds
    }
  }, [disabledFeeds]);

  const handleCheckboxChange = (feed, isChecked) => {
    setSelectedFeeds((prevChoices) => {
      const isThere = prevChoices.some((choice) => choice.alias === feed.alias);
      if (isThere) {
        return prevChoices.filter((choice) => choice.alias !== feed.alias)
      };
      return [...prevChoices, feed];
    });
  };

  const handleAddChoices = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/api/v1/payments/custom-display-feeds/`,
        { choice: selectedFeeds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDone(true);
      setConfirmSelection(false);
      fetchSubscribedFeeds();
      setLoading(false);
    } catch (error) {
      console.error("Error fetching subscription:", error);
    } finally {
      setLoading(false);
    }
  };

  const freeProviders = providers.filter((provider) => provider.non_profit_org);
  const paidProviders = providers.filter(
    (provider) => !provider.non_profit_org
  );

  const isFeedSelected = (alias) => {
    return selectedFeeds.some((feed) => feed.alias === alias);
  };

  if(!subscription && !subscription.display_feeds){
    setLoading(true)
    fetchSubscribedFeeds();
    fetchSubscription();
  }

  if (loading){
    return  <CenteredLoader />
  }

  return (
    <>
      <ConfirmFeeds
        choices={selectedFeeds}
        open={confrimSelection}
        setShowModal={setConfirmSelection}
        addChoices={handleAddChoices}
        loading={loading}
      />
      <CannotSelect open={error} setShowModal={showError} />
      <SuccessSelect open={done} setShowModal={setDone} />
      {((subscription && !subscription.alias) || !subscription) && (
        <div
          class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4"
          role="alert"
        >
          <p class="font-bold">Notice</p>
          <p>
            Your subscription has ended. Please{" "}
            <button
              onClick={() => navigate("/dashboard/subscription/")}
              class="text-blue-600 underline hover:text-blue-800"
            >
              re-subscribe
            </button>{" "}
            to continue.
          </p>
        </div>
      )}
      <div className="mt-6 space-y-1">
        <h2 className="text-2xl font-semibold tracking-tight">Feeds</h2>
        <p className="text-sm text-muted-foreground">
          Your personal feeds list.
        </p>
      </div>
      <Separator />
      <div className="relative mt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {subscription && subscription.feeds && subscription.feeds.length > 0 ? (
            subscription.feeds.map((item) => (
              <CheckboxWithText
                key={item.alias}
                feed={item}
                // disabled={disabledFeeds[item.alias]}
                checked={isFeedSelected(item.alias)}
                onChange={(isChecked) => handleCheckboxChange(item, isChecked)}
              />
            ))
          ) : (
            <p className="text-sm text-muted-foreground">
              No paid providers available.
            </p>
          )}
        </div>
      </div>
      {subscription && subscription.alias && (
        <>
          <div className="fixed bottom-5 right-5">
            <button
              onClick={() => {
                handleAddChoices();
              }}
              disabled={loading}
              className="bg-green-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </>
      )}
    </>
  );
}




